<template>
  <v-layout justify-space-between column fill-height>
    <div class="box-form fill-height">
      <v-row no-gutters class="fill-height">
        <v-col cols="12" md="12" sm="12" lg="12">
          <v-sheet v-if="loading" color="white" class="pa-3">
            <v-skeleton-loader
              class="mx-auto"
              type="list-item-avatar, heading, heading"
            ></v-skeleton-loader>
          </v-sheet>
          <v-form v-else ref="form" @submit.prevent="save()">
            <v-card flat>
              <v-card-title class="register-group-title">
                <v-icon class="material-icons">description</v-icon>
                <span class="ml-3">{{ $t("company.form.label") }}</span>
              </v-card-title>
              <v-card-text>
                <v-text-field
                  v-model.trim="formCopy.name"
                  class="mt-3"
                  :label="`*${fieldName.label}`"
                  :rules="fieldName.validation"
                  :hint="fieldName.hint"
                  persistent-hint
                  outlined
                  autocomplete="off"
                  color="flow-color"
                >
                </v-text-field>

                <v-autocomplete
                  v-model="formCopy.company_group_id"
                  class="mt-3"
                  :items="fieldCompanyGroup.items"
                  :loading="fieldCompanyGroup.loading"
                  :rules="fieldCompanyGroup.validation"
                  :search-input.sync="searchCompanyGroup"
                  :label="`*${fieldCompanyGroup.label}`"
                  cache-items
                  validate-on-blur
                  hide-no-data
                  hide-selected
                  item-text="name"
                  item-value="id"
                  placeholder="Comece a digitar para pesquisar"
                  outlined
                  clearable
                  color="flow-color"
                ></v-autocomplete>

                <v-checkbox
                  v-model="formCopy.active"
                  :label="fieldActive.label"
                  class="mt-0"
                  color="flow-color"
                  hide-details
                >
                </v-checkbox>
              </v-card-text>
            </v-card>
            <v-card v-if="role() === 'admin'" flat class="d-none">
              <v-card-title class="register-group-title">
                <v-icon>mdi-shield-lock</v-icon>
                <span class="ml-3">{{
                  $t("company.form.password_policy")
                }}</span>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-checkbox
                      v-model="changePasswordAfterTime"
                      :label="fieldChangePasswordAfterTime.label"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row v-if="showConfigPassword">
                  <v-col cols="12" md="6"
                    ><v-select
                      v-model="passwordResetUnit"
                      :label="fieldPasswordResetUnit.label"
                      :items="fieldPasswordResetUnit.items"
                      :rules="fieldPasswordResetUnit.validation"
                      item-text="name"
                      item-value="value"
                      return-object
                      outlined
                      color="flow-color"
                    >
                    </v-select
                  ></v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="passwordResetPeriod"
                      :label="fieldPasswordResetPeriod.label"
                      :rules="fieldPasswordResetPeriod.validation"
                      outlined
                      color="flow-color"
                      :suffix="suffix"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card v-if="role() === 'admin'" flat>
              <v-card-title class="register-group-title">
                <v-icon>mdi-arrow-decision-outline</v-icon>
                <span class="ml-3">{{ $t("company.form.integrations") }}</span>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols md="12">
                    <v-text-field
                      v-model="formCopy.teams_webhook"
                      :label="$t('company.form.teams_integration_label')"
                      :hint="$t('company.form.hint.teams_url')"
                      outlined
                      color="flow-color"
                      prepend-inner-icon="mdi-microsoft-teams"
                      persistent-hint
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card v-if="role() === 'admin'" flat>
              <v-card-title class="register-group-title">
                <v-icon>mdi-monitor-dashboard</v-icon>
                <span class="ml-3">{{ $t("company.form.bi") }}</span>
              </v-card-title>
              <v-card-text>
                <div class="list-bis-cards pb-4">
                  <v-row>
                    <v-col
                      v-for="(bi, i) in formCopy.bis"
                      :key="bi.name"
                      cols="12"
                      md="6"
                    >
                      <v-card elevation="1">
                        <v-card-title class="pb-1 d-flex flex-nowrap">
                          <span
                            class="bi-name text-truncate mr-auto font-weight-light"
                            >{{ bi.name }}</span
                          >
                          <div class="ml-2 actions d-flex flex-nowrap">
                            <v-btn x-small icon @click="openEdit(bi, i)">
                              <v-icon class="material-icons">edit</v-icon>
                            </v-btn>
                            <v-btn x-small icon @click="confirmRemove(i)">
                              <v-icon class="material-icons">delete</v-icon>
                            </v-btn>
                          </div>
                        </v-card-title>
                        <v-card-text class="d-flex">
                          <span class="text-truncate">{{ bi.url }}</span>
                          <v-spacer></v-spacer>
                          <v-btn x-small icon @click="goTo(bi)">
                            <v-icon class="material-icons">open_in_new</v-icon>
                          </v-btn>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </div>
                <v-divider
                  v-if="formCopy.bis && formCopy.bis.length"
                ></v-divider>
                <v-form ref="formBi">
                  <v-text-field
                    v-model.trim="biForm.name"
                    class="mt-3"
                    :label="fieldBIName.label"
                    :hint="fieldBIName.hint"
                    :rules="fieldBIName.validation"
                    persistent-hint
                    outlined
                    autocomplete="off"
                    color="flow-color"
                  >
                  </v-text-field>

                  <v-text-field
                    v-model.trim="biForm.url"
                    class="mt-3"
                    :label="fieldBIUrl.label"
                    :rules="fieldBIUrl.validation"
                    :hint="fieldBIUrl.hint"
                    persistent-hint
                    outlined
                    autocomplete="off"
                    color="flow-color"
                  >
                  </v-text-field>
                </v-form>
                <v-btn
                  dark
                  depressed
                  color="flow-color"
                  @click.stop="addBI()"
                  >{{
                    biSelectedIndex != null
                      ? $t("default.btn.update")
                      : $t("default.btn.add")
                  }}</v-btn
                >
              </v-card-text>
              <confirm-dialog
                v-model="dialogConfirm"
                :label="`BI: ${biSelected.name}`"
                :purge="true"
              >
                <template v-slot:delete>
                  <v-btn
                    color="flow-color white--text elevation-0"
                    @click="removeBI()"
                  >
                    {{ $t("default.yes") }}
                  </v-btn>
                </template>
              </confirm-dialog>
            </v-card>
          </v-form>
        </v-col>
      </v-row>
    </div>
    <div class="justify-end">
      <v-divider></v-divider>
      <div class="d-flex flex-row-reverse ma-4">
        <v-btn
          color="flow-color"
          class="white--text"
          depressed
          :loading="loadingBtn"
          @click="clickSave()"
          >{{ $t("default.save") }}</v-btn
        >
        <v-btn text class="mr-4" @click="closeDialog()">{{
          $t("default.cancel")
        }}</v-btn>
      </div>
    </div>
  </v-layout>
</template>
<script>
import { companyGroupService } from "@/services";
import { mapGetters } from "vuex";
import formMixin from "@/mixins/formMixin";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
export default {
  components: {
    ConfirmDialog,
  },
  mixins: [formMixin("Company")],
  data() {
    return {
      suffix: "",
      showConfigPassword: false,
      model: false,
      formCopy: {},
      loadingBtn: false,
      loading: false,
      searchCompanyGroup: "",
      dialogConfirm: false,
      biSelectedIndex: null,
      biSelected: {},
      biForm: {
        name: null,
        url: null,
      },
      fieldName: {
        label: this.$t("company.form.name"),
        validation: [(v) => !!v || this.$t("company.form.rules.name_required")],
        hint: this.$t("company.form.hint.name"),
      },
      fieldCompanyGroup: {
        label: this.$t("company.form.company_group"),
        validation: [
          (v) => !!v || this.$t("company.form.rules.company_group_required"),
        ],
        loading: false,
        items: [],
      },
      fieldChangePasswordAfterTime: {
        label: this.$t("company.form.change_password_after_time"),
      },
      fieldPasswordResetUnit: {
        label: this.$t("company.form.password_reset_unit"),
        validation: [
          (v) => {
            let a = true;
            if (this.showConfigPassword) {
              a = false;
            }
            if (!!v && !a) {
              a = true;
            }
            return a || this.$t("company.form.rules.password_reset_unit");
          },
        ],
        items: [
          {
            name: this.$t("company.form.units_password_reset.days"),
            value: "days",
          },
          {
            name: this.$t("company.form.units_password_reset.months"),
            value: "months",
          },
        ],
      },
      fieldPasswordResetPeriod: {
        label: this.$t("company.form.password_reset_period"),
        validation: [
          (v) => {
            let a = true;
            if (this.showConfigPassword) {
              a = false;
            }
            if (!!v && !a) {
              a = true;
            }
            return a || this.$t("company.form.rules.password_reset_period");
          },
        ],
      },
      fieldActive: {
        label: this.$t("company.form.active"),
      },
      fieldBIName: {
        label: this.$t("company.form.bi_name"),
        hint: this.$t("company.form.hint.bi_name"),
        validation: [
          (v) => !!v || this.$t("company.form.rules.bi_name_required"),
        ],
      },
      fieldBIUrl: {
        label: this.$t("company.form.bi_url"),
        validation: [
          (v) => !!v || this.$t("company.form.rules.bi_url_required"),
        ],
        hint: this.$t("company.form.hint.bi_url"),
      },
    };
  },
  computed: {
    changePasswordAfterTime: {
      set(val) {
        if (!this.formCopy.password_policy) {
          this.formCopy["password_policy"] = {};
        }
        this.showConfigPassword = val;
        this.formCopy.password_policy.enabled = val;
      },
      get() {
        return this.formCopy?.password_policy?.enabled ?? false;
      },
    },
    passwordResetUnit: {
      set(val) {
        if (!this.formCopy.password_policy) {
          this.formCopy["password_policy"] = {};
        }
        this.suffix = val?.name ?? "";
        this.formCopy.password_policy.password_reset_unit = val?.value ?? null;
      },
      get() {
        return this.formCopy?.password_policy?.password_reset_unit ?? null;
      },
    },
    passwordResetPeriod: {
      set(val) {
        if (!this.formCopy.password_policy) {
          this.formCopy["password_policy"] = {};
        }
        this.formCopy.password_policy.password_reset_period = val;
      },
      get() {
        return this.formCopy?.password_policy?.password_reset_period ?? null;
      },
    },
  },
  watch: {
    searchCompanyGroup(val) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.fieldCompanyGroup.loading = true;
        companyGroupService.getListAutocomplete(val).then((resp) => {
          this.fieldCompanyGroup.items = resp;
          this.fieldCompanyGroup.loading = false;
        });
      }, 500);
    },
    form(val) {
      this.formCopy = this.$_.cloneDeep(this.form);

      this.showConfigPassword =
        this.formCopy?.password_policy?.enabled ?? false;

      this.suffix = this.formCopy?.password_policy?.password_reset_unit
        ? this.$t(
            `company.form.units_password_reset.${this.formCopy?.password_policy?.password_reset_unit}`
          )
        : "";

      if (val.company_group?.id) {
        this.fieldCompanyGroup.items = [
          { id: val.company_group.id, name: val.company_group.name },
        ];
        this.formCopy.company_group_id = val.company_group.id;
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
  },
  methods: {
    ...mapGetters("Account", { role: "getRole" }),
    addBI() {
      if (this.$refs.formBi.validate()) {
        if (Number.isInteger(this.biSelectedIndex)) {
          this.formCopy.bis[this.biSelectedIndex].name = this.biForm.name;
          this.formCopy.bis[this.biSelectedIndex].url = this.biForm.url;
        } else {
          if (!this.formCopy.bis) {
            this.formCopy["bis"] = [];
          }
          this.formCopy.bis.push({
            name: this.biForm.name,
            url: this.biForm.url,
          });
        }
        this.biForm.name = null;
        this.biForm.url = null;
        this.biSelectedIndex = null;
        this.$refs.formBi.reset();
      }
    },
    openEdit(bi, index) {
      this.biSelectedIndex = index;
      this.biForm.name = bi.name;
      this.biForm.url = bi.url;
    },
    confirmRemove(index) {
      this.biSelected = this.formCopy.bis[index];
      this.biSelectedIndex = index;
      this.dialogConfirm = true;
    },
    removeBI() {
      this.formCopy.bis.splice(this.biSelectedIndex, 1);
      this.dialogConfirm = false;
      this.biSelectedIndex = null;
      this.biSelected = {};
    },
    goTo(bi) {
      if (bi.url) {
        window.open(bi.url);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.bi-name {
  color: #404143 !important;
  font-size: 16px !important;
}
</style>
